<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/stream.jpeg')"
      class="white--text"
      gradient="to bottom, rgba(0, 105, 92, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-subheading
            weight="regular"
            title="SOCIAL THE WAY IT SHOULD BE"
          />

          <base-heading
            size="text-h2"
            title="MORE ABOUT ME"
            weight="medium"
          />

          <base-body>
            Build and curate content streams. Get to know others. Enjoy competing perspectives.
            Avoid missing out without staying connected constantly.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn
                href="/signup"
            >
              Try Now
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

            <base-btn
              :ripple="false"
              class="accent--text"
              height="auto"
              href="/about"
              text
            >
              Learn More
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>

export function getBaseURL(suffix) {
    let host = process.env.VUE_APP_API_HOST
    let outputURL = suffix
    if (host) {
        outputURL = host + "/" + suffix
        // remove "double slashes" (but not for https://)
        let re = /([^:])\/\//g
        outputURL = outputURL.replaceAll(re, '$1/')
    }
    return outputURL
}

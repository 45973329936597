export const Action = {
    FetchCurrentStream: 'FetchCurrentStream',
    FetchStreams: 'FetchStreams',
    PostStream: 'PostStream',
    FetchMessages: 'FetchMessages',
    PostMessage: 'PostMessage',
}

export const Mutation = {
    SetCurrentStream: 'SetCurrentStream',
    SetStreams: 'SetStreams',
    SetMessages: 'SetMessages',
}

export const Getter = {
    GetCurrentStream: 'GetCurrentStream',
    GetStreams: 'GetStreams',
    GetMessages: 'GetMessages',
}

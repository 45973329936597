import {Getter} from './types';
export default {
    [Getter.GetCurrentStream](state) {
        return state.currentStream
    },
    [Getter.GetStreams](state) {
        return state.streams
    },
    [Getter.GetMessages](state) {
        return state.messages
    },
}

<template>
  <div>
    <section-hero />
    <section-features />
    <big-boom-img />
    <big-boom />
    <home-footer />
  </div>
</template>

<script>
  import SectionHero from "@/views/sections/Hero";
  import HomeFooter from "@/layouts/home/Footer";
  import SectionFeatures from "@/views/sections/Features";
  import BigBoom from "@/views/sections/BigBoom";
  import BigBoomImg from "@/views/sections/BigBoomImg";

  export default {
    name: 'Home',

    components: {
      BigBoomImg,
      BigBoom,
      SectionFeatures,
      HomeFooter,
      SectionHero,
    },
  }
</script>

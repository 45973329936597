import Vue from 'vue'
import Vuex from 'vuex'

import {getBaseURL} from "@/store/helpers";

Vue.use(Vuex)

Vue.axios.defaults.baseURL = getBaseURL("/__api__/model/");

import anonymous from './anonymous/index'
import session from './session/index'
import openapi from './openapi/index'
import stream from './stream/index'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    anonymous,
    session,
    openapi,
    stream,
  }
})

<template>
  <base-section
    id="features"
    class="secondary"
    dark
  >
    <v-responsive
        class="mx-auto"
        max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
              v-for="card in cards"
              :key="card.title"
              cols="12"
              sm="6"
              md="3"
          >
            <base-info-card
                align="center"
                dark
                v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-podium-gold',
          title: 'Me First',
          text: 'Designed for me. Not for advertisers. If you are not paying for it, you are the product being sold.',
        },
        {
          icon: 'mdi-bell-off-outline',
          title: 'Minimal Notifications',
          text: 'More time for real life. Less time with in-your-face and attention-grabbing software.',
        },
        {
          icon: 'mdi-account-filter-outline',
          title: 'Content Curation',
          text: 'All the tools for content curation in the palm of your hand. Manage content the way that you want to.',
        },
        {
          icon: 'mdi-lock-check-outline',
          title: 'Safe and Secure',
          text: 'Manage access and decide who can see your streams. Your data is your data.',
        },
      ],
    }),
  }
</script>

<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-btn :href="getHomeLink()" text>
          <h1>MeStream</h1>
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      <login-button />
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import LoginButton from "@/components/session/LoginButton";
import {Action as SessionAction, Getter as SessionGetter} from '@/store/session/types'
export default {
  name: 'App',
  components: {LoginButton},
  data: () => ({
    //
  }),
  computed: {
    isLoggedIn() {
      return this.$store.getters[`${[SessionGetter.IsLoggedIn]}`]
    }
  },
  methods: {
    getHomeLink() {
      if (this.isLoggedIn) {
        return "/home"
      } else {
        return "/"
      }
    }
  },
  mounted() {
    this.$store.dispatch(`${[SessionAction.CheckLoggedIn]}`)
  },
};
</script>

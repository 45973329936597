import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify/index.js'
import './plugins'
import '@/plugins/vue-composition-api'

Vue.config.productionTip = false

import VueMatomo from 'vue-matomo'

Vue.use(VueMatomo, {
  host: 'https://www.mestream.io',
  siteId: 3,
  router: router,
  enableLinkTracking: true,
  requireConsent: false, // TODO: want to do it
  trackInitialView: true,
  disableCookies: false, // TODO: want to do it
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  debug: false,
  domains: '*.mestream.io'
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

<template>
  <v-theme-provider dark>
    <base-section
      id="contact"
      class="accent text-center"
      space="0"
    >
      <v-parallax
        height="500"
        :src="require('@/assets/computer.jpeg')"
      >
          <v-container class="fill-height px-4 py-12">
            <v-row no-gutters>
              <v-col cols="0" sm="6" />
              <v-col cols="12" sm="6">
                <base-section-heading
                    color="transparent"
                    title="Contact Us"
                >
                  Currently preparing for an early stage alpha. We would love to hear from you.
                </base-section-heading>

                <div class="py-4" />

                <v-btn
                    class="font-weight-bold"
                    color="white"
                    href="/signup"
                    light
                    min-width="168"
                    x-large
                >
                  Get In Touch
                </v-btn>
              </v-col>
            </v-row>

          </v-container>

      </v-parallax>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BigBoomImg',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>

import {Action, Mutation} from './types'
import Vue from "vue";

export default {
    [Action.Login]({commit, dispatch}, {username, password}) {
        const loginData = {
            login: username,
            password: password
        };
        return Vue.axios.post('/rpc/login', loginData, {
            headers: {
                'Content-Profile': 'login',
                'Content-Type': 'application/json',
            }
        }).then(
            result => {
                localStorage.setItem('user', JSON.stringify(result.data.token))

                commit(`${[Mutation.LoggedIn]}`, true)
                dispatch(`${[Action.CheckLoggedIn]}`)
            }
        )
    },
    [Action.Logout]({commit, dispatch}) {
        localStorage.removeItem('user');
        commit(`${[Mutation.LoggedIn]}`, false)
        dispatch(`${[Action.CheckLoggedIn]}`)
    },
    [Action.CheckLoggedIn]({commit}) {
        let isLoggedIn = false;
        isLoggedIn = !!localStorage.getItem('user');
        commit(`${[Mutation.LoggedIn]}`, isLoggedIn)
    },
}

import Vue from 'vue';
import {Mutation} from './types';
export default {
    [Mutation.SetCurrentStream](state, payload) {
        Vue.set(state, 'currentStream', payload)
    },
    [Mutation.SetStreams](state, payload) {
        Vue.set(state, 'streams', payload)
    },
    [Mutation.SetMessages](state, payload) {
        Vue.set(state, 'messages', payload)
    },
}

import {Action, Mutation} from './types';
import Vue from 'vue'
import {authHeader, singleObjectAcceptHeader} from "@/store/headers";
export default {
    [Action.FetchCurrentStream]({commit}, {streamGuid}) {
        return new Promise(resolve => {
            return Vue.axios.get(
                `stream?guid=eq.${streamGuid}`,
                {headers: {...authHeader(), 'Accept': singleObjectAcceptHeader}}
            ).then(
                (result) => {
                    commit(`${[Mutation.SetCurrentStream]}`, result.data)
                    resolve()
                }
            ).catch(
                err => {
                    resolve()
                    throw new Error(`API Error ${err}`)
                }
            )
        })
    },
    [Action.FetchStreams]({commit}) {
        return new Promise(resolve => {
            return Vue.axios.get(
                'stream',
                {headers: authHeader()}
            ).then(
                (result) => {
                    commit(`${[Mutation.SetStreams]}`, result.data)
                    resolve()
                }
            ).catch(
                err => {
                    throw new Error(`API error ${err}`)
                }
            )
        })
    },
    [Action.PostStream]({dispatch}, {name}) {
        return new Promise(resolve => {
            return Vue.axios.post(
                'stream',
                {name: name},
                {headers: authHeader()}
            ).then(
                (result) => {
                    console.log(result)
                    let res = dispatch(`${[Action.FetchStreams]}`)
                    res.then(() => {
                        resolve()
                    })
                }
            ).catch(
                err => {
                    resolve()
                    throw new Error(`API error ${err}`)
                }
            )
        })
    },
    [Action.FetchMessages]({commit}, {streamGuid}) {
        return new Promise(resolve => {
            return Vue.axios.get(
                `message?select=*,stream!inner(streamid,guid)&stream.guid=eq.${streamGuid}&order=ts.desc`,
                {headers: authHeader()}
            ).then(
                (result) => {
                    commit(`${[Mutation.SetMessages]}`, result.data)
                    resolve()
                }
            ).catch(
                err => {
                    resolve()
                    throw new Error(`API error ${err}`)
                }
            )
        })
    },
    // TODO: set up the ability to edit...
    [Action.PostMessage]({dispatch}, {message, streamGuid, ts}) {
        return new Promise(resolve => {
            return Vue.axios.get(
                `stream?select=streamid&guid=eq.${streamGuid}`,
                {headers: {...authHeader(), 'Accept': singleObjectAcceptHeader}}
            ).then(
                (res) => {
                    // TODO: what if more than one record comes back...? That's bad
                    const streamid = res.data.streamid
                    Vue.axios.post(
                        'message',
                        {streamid: streamid, bodyjson: message, ts: ts},
                        {headers: authHeader()},
                    ).then(
                        res2 => {
                            console.log(res2)
                            let update = dispatch(`${[Action.FetchMessages]}`, {streamGuid: streamGuid})
                            update.then(
                                () => {
                                    resolve()
                                }
                            )
                        }
                    ).catch(
                        err => {
                            resolve()
                            throw new Error(`API error ${err}`)
                        }
                    )
                }
            ).catch(
                err => {
                    resolve()
                    throw new Error(`API error ${err}`)
                }
            )
        })
    }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/pages/Register')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/pages/Login')
  },
  {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "login" */ '../views/pages/Logout')
  },
    {
        path: '/home',
        name: 'appHome',
        component: () => import(/* webpackChunkName: "appHome" */ '../views/app/AppHome')
    },
    {
        path: '/stream/:guid',
        name: 'stream',
        component: () => import(/* webpackChunkName: "stream" */ '../views/app/Stream'),
        params: true,
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (/*to, from, savedPosition*/) {
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
    return {x: 0, y:0}
  }
})

router.beforeEach(
    (to, from, next) => {
      const publicPages = ['/login', '/about', '/signup', '/'];
      const authRequired = !publicPages.includes(to.path);
      const loggedIn = localStorage.getItem('user');

      // redirect to login page if not logged in...
      if (authRequired && !loggedIn) {
        next({path: '/login', query: {redirect: to.path}})
      } else {
        next();
      }
    }
)

export default router
